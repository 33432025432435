<div class="title">
  <h1>VELUX</h1>
</div>
<!-- <div id="banner">
  <div id="banner-title" class="bigscreen">
    <h1>Zonnewering</h1>
  </div>
  <div
    id="banner-img"
  ></div>
</div> -->
<div class="divider"></div>
<div id="merken">
  <div class="merk">
    <div class="logo"><img src="../assets/logo/velux.svg" /></div>
    <div class="fotos">
      <div class="foto1"></div>
      <div class="foto2"></div>
      <div class="foto3"></div>
      <div class="foto4"></div>
      <div class="foto5"></div>
      <div class="foto6"></div>
    </div>
  </div>
</div>
