import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gordijnen',
  templateUrl: './gordijnen.component.html',
  styleUrls: ['./gordijnen.component.scss']
})
export class GordijnenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
