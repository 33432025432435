import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarkerAlt, faShoppingCart, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ZonneweringComponent } from './zonnewering/zonnewering.component';
import { GordijnenComponent } from './gordijnen/gordijnen.component';
import { SquidComponent } from './squid/squid.component';
import { VeluxComponent } from './velux/velux.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';

const appRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'zonwering', component: ZonneweringComponent},
  {path: 'gordijnen', component: GordijnenComponent},
  {path: 'squid', component: SquidComponent},
  {path: 'velux', component: VeluxComponent},
  {path: 'contact', component: ContactComponent},
  {path: '**', component: HomeComponent},
  {path: '', component: HomeComponent},
]
@NgModule({
  declarations: [
    AppComponent,
    ZonneweringComponent,
    GordijnenComponent,
    SquidComponent,
    VeluxComponent,
    HomeComponent,
    ContactComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes, {enableTracing: false, scrollPositionRestoration: 'enabled', useHash: true }
    ),
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor (library: FaIconLibrary) {
    library.addIcons(faFacebook);
    library.addIcons(faInstagram);
    library.addIcons(faPhone);
    library.addIcons(faEnvelope);
    library.addIcons(faMapMarkerAlt);
    library.addIcons(faShoppingCart);
    library.addIcons(faBars);
  }
}
