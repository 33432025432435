<div class="title">
  <h1>ZONWERING</h1>
</div>
<!-- <div id="banner">
  <div id="banner-title" class="bigscreen">
    <h1>Zonnewering</h1>
  </div>
  <div
    id="banner-img"
  ></div>
</div> -->
<div class="divider"></div>
<div id="merken">
  <div class="merk" id="louverdrape">
    <div class="logo"> <img src="../assets/logo/Louverdrape.jpeg" /></div>
    <div class="fotos">
      <div class="louverdrape1"></div>
      <div class="louverdrape2"></div>
      <div class="louverdrape3"></div>
      <div class="louverdrape4"></div>
      <div class="louverdrape5"></div>
      <div class="louverdrape6"></div>
    </div>
  </div>
</div>
