<div class="gegevens">
  <!-- <div class="winkel1">
    <div class="address">
      <h1>WINKEL VOSSELAAR</h1>
      <div class="straat">Antwerpsesteenweg 90</div>
      <div class="gemeente">2350, Vosselaar</div>
      <div class="mail">raamdecoratie-vosselaar@groeptom.be</div>
      <div class="tel">014/61.23.17</div>
    </div>
    <div class="uren">
      <h2>OPENINGSUREN</h2>
      <div class="uur">
        <div>Maandag</div>
        <div>8u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Dinsdag</div>
        <div>8u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Woensdag</div>
        <div>8u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Donderdag</div>
        <div>8u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Vrijdag</div>
        <div>8u - 12u 13u - 17u</div>
      </div>
      <div class="uur">
        <div>Zaterdag</div>
        <div>8u - 13u</div>
      </div>
      <div class="uur">
        <div>Zondag</div>
        <div>gesloten</div>
      </div>
      <div id="kersturen">
        <h2>Afwijkende uren</h2>
        <div class="uur">
          <div>01/04 - 06/04</div>
          <div>gesloten</div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
        &q=Goe-Decor - Sigmashop - Vosselaar
        &zoom=16"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div> -->
  <div class="winkel2">
    <div class="address">
      <h1>T2 RAAMDECORATIE</h1>
      <div class="straat">Turfputten 1</div>
      <div class="gemeente">2250, Olen</div>
      <div class="mail">tist.peeters@sigmashop-olen.be</div>
      <div class="tel">014/26.31.01</div>
    </div>
    <div class="uren">
      <h2>OPENINGSUREN</h2>
      <div class="uur">
        <div>Maandag</div>
        <div>7u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Dinsdag</div>
        <div>7u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Woensdag</div>
        <div>7u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Donderdag</div>
        <div>7u - 12u 13u - 18u</div>
      </div>
      <div class="uur">
        <div>Vrijdag</div>
        <div>7u - 12u 13u - 17u</div>
      </div>
      <div class="uur">
        <div>Zaterdag</div>
        <div>8u - 12u</div>
      </div>
      <div class="uur">
        <div>Zondag</div>
        <div>gesloten</div>
      </div>
      <!-- <div id="kersturen">
        <h2>Afwijkende uren</h2>
        <div class="uur">
          <div>Maandag 01/04</div>
          <div>gesloten</div>
        </div>
        <div class="uur">
          <div>Dinsdag 02/04</div>
          <div>gesloten</div>
        </div>
      </div> -->
    </div>
    <div class="map">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
        &q=Sigmashop - Goe-Decor - Olen
        &zoom=17"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
