<div class="title">
  <h1>GORDIJNEN</h1>
</div>
<div class="divider"></div>
<div id="merken">
  <div class="merk" id="ado">
    <div class="logo"><img src="../assets/logo/ADO.jpg" /></div>
    <div class="fotos">
      <div class="ado1"></div>
      <div class="ado2"></div>
      <div class="ado3"></div>
      <div class="ado4"></div>
      <div class="ado5"></div>
      <div class="ado6"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="merk" id="bm">
    <div class="logo"><img src="../assets/logo/b&m.png" /></div>
    <div class="fotos">
      <div class="bm1"></div>
      <div class="bm2"></div>
      <div class="bm3"></div>
      <div class="bm4"></div>
      <div class="bm5"></div>
      <div class="bm6"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="merk" id="camengo">
    <div class="logo"> <img src="../assets/logo/camengo.svg" /></div>
    <div class="fotos">
      <div class="camengo1"></div>
      <div class="camengo2"></div>
      <div class="camengo3"></div>
      <div class="camengo4"></div>
      <div class="camengo5"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="merk" id="casamance">
    <div class="logo"><img src="../assets/logo/casamance.svg" /></div>
    <div class="fotos">
      <div class="casamance1"></div>
      <div class="casamance2"></div>
      <div class="casamance3"></div>
      <div class="casamance4"></div>
      <div class="casamance5"></div>
      <div class="casamance6"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="merk" id="vano">
    <div class="logo"><img src="../assets/logo/vano.jpeg" /></div>
    <div class="fotos">
      <div class="vano1"></div>
      <div class="vano2"></div>
      <div class="vano3"></div>
      <div class="vano4"></div>
      <div class="vano5"></div>
      <div class="vano6"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="merk" id="eijffinger">
    <div class="logo"><img src="../assets/logo/eijffinger.svg" /></div>
    <div class="fotos">
      <div class="eijffinger1"></div>
      <div class="eijffinger2"></div>
      <div class="eijffinger3"></div>
      <div class="eijffinger4"></div>
      <div class="eijffinger5"></div>
      <div class="eijffinger6"></div>
      <div class="eijffinger7"></div>
    </div>
  </div>
</div>
