<div id="content">
  <div id="banner">
    <div id="banner-logo" class="bigscreen">
      <img src="../../assets/logo/Logo-T2_raam.svg" />
    </div>
    <div
      id="banner-img"
      style="
        background-image: url('../../assets/images/achtergrond_toppoint.jpg');
      "
    ></div>
  </div>
  <div id="info">
    <div id="info-text">
      <h1>INFO OVER T2 RAAMDECORATIE</h1>
      <div>
        <p>
          T2 Raamdecoratie is een jong en dynamisch bedrijf met de nodige ervaring op
          vlak van binnenhuisinrichting.
        </p>
        <p>
          Wij helpen u verder met persoonlijk advies voor het maken van uw keuze
          in verband met kleuren in verf, behang, gordijnen en zonwering.
        </p>
        <!-- <p>In ons eigen atelier worden de gordijnen gemaakt.</p> -->
      </div>
    </div>
    <div class="gegevens">
      <!-- <div class="winkel1">
        <div class="address">
          <h1>WINKEL VOSSELAAR</h1>
          <div class="straat">Antwerpsesteenweg 90</div>
          <div class="gemeente">2350, Vosselaar</div>
          <div class="mail">raamdecoratie-vosselaar@groeptom.be</div>
          <div class="tel">014/61.23.17</div>
        </div>
        <div class="uren">
          <h2>OPENINGSUREN</h2>
          <div class="uur">
            <div>Maandag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Dinsdag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Woensdag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Donderdag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Vrijdag</div>
            <div>8u - 12u 13u - 17u</div>
          </div>
          <div class="uur">
            <div>Zaterdag</div>
            <div>8u - 13u</div>
          </div>
          <div class="uur">
            <div>Zondag</div>
            <div>gesloten</div>
          </div>
          <div id="kersturen">
            <h2>Afwijkende uren</h2>
            <div class="uur">
              <div>01/04 - 06/04</div>
              <div>gesloten</div>
            </div>
          </div>
        </div>
        <div class="map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
            &q=Goe-Decor - Sigmashop - Vosselaar
            &zoom=16"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div> -->
      <div class="winkel2">
        <div class="address">
          <h1>T2 RAAMDECORATIE</h1>
          <div class="straat">Turfputten 1</div>
          <div class="gemeente">2250, Olen</div>
          <div class="mail">tist.peeters@sigmashop-olen.be</div>
          <div class="tel">014/26.31.01</div>
        </div>
        <div class="uren">
          <h2>OPENINGSUREN</h2>
          <div class="uur">
            <div>Maandag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Dinsdag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Woensdag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Donderdag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Vrijdag</div>
            <div>7u - 12u 13u - 17u</div>
          </div>
          <div class="uur">
            <div>Zaterdag</div>
            <div>8u - 12u</div>
          </div>
          <div class="uur">
            <div>Zondag</div>
            <div>gesloten</div>
          </div>
          <!-- <div id="kersturen">
            <h2>Afwijkende uren</h2>
            <div class="uur">
              <div>Maandag 01/04</div>
              <div>gesloten</div>
            </div>
            <div class="uur">
              <div>Dinsdag 02/04</div>
              <div>gesloten</div>
            </div>
          </div> -->
          <!-- <div id="kersturen">
            <h2>Afwijkende uren</h2>
            <div class="uur">
              <div>Zaterdag 11/11</div>
              <div>gesloten</div>
            </div>
          </div> -->
        </div>
        <div class="map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
            &q=Sigmashop - Goe-Decor - Olen
            &zoom=17"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div id="soorten">
    <div
      class="soort-card"
      routerLink="../zonwering"
      style="
        background-image: url('../assets/images/inspiratie/zonnewering_hori_1.jpeg');
      "
    >
      <div class="card-text">
        <h2>ZONWERING</h2>
        <h3>Bekijk hier onze merken en voor inspiratie</h3>
        <button routerLink="../zonwering">BEKIJK</button>
      </div>
    </div>
    <div
      class="soort-card"
      routerLink="../gordijnen"
      style="
        background-image: url('../../assets/images/inspiratie/gordijn_hori_1.jpeg');
      "
    >
      <div class="card-text">
        <h2>GORDIJNEN</h2>
        <h3>Bekijk hier onze merken en voor inspiratie</h3>
        <button routerLink="../gordijnen">BEKIJK</button>
      </div>
    </div>
    <div
      class="soort-card"
      routerLink="../squid"
      style="
        background-image: url('../../assets/images/inspiratie/squid_1.jpeg');
      "
    >
      <div class="card-text">
        <h2>SQUID</h2>
        <h3>Bekijk hier onze merken en voor inspiratie</h3>
        <button routerLink="../squid">BEKIJK</button>
      </div>
    </div>
    <div
      class="soort-card"
      routerLink="../velux"
      style="
        background-image: url('../../assets/images/inspiratie/velux_hori_1.jpeg');
      "
    >
      <div class="card-text">
        <h2>VELUX</h2>
        <h3>Bekijk hier onze merken en voor inspiratie</h3>
        <button routerLink="../velux">BEKIJK</button>
      </div>
    </div>
  </div>
  <!-- <div class="divider"></div>
  <div id="groeptom" data-aos="fade-up">
    <h1>GOE-DECOR IS DEEL VAN GROEP T.O.M</h1>
    <div id="logo" (click)="goToUrl('https://www.groeptom.be')">
      <img src="../assets/images/logo_tom.png" />
    </div>
    <div id="buttons">
      <div
        class="button"
        id="tom"
        (click)="goToUrl('https://www.groeptom.be/tom')"
      >
        <img
          class="button-logo"
          src="../assets/images/totaalprojecten-icon.svg"
        />
        <div class="button-naam">TOTAAL PROJECTEN OP MAAT</div>
      </div>
      <div
        class="button"
        id="kom"
        (click)="goToUrl('https://www.groeptom.be/kom')"
      >
        <img class="button-logo" src="../assets/images/kleuropmaat-icon.svg" />
        <div class="button-naam">KLEUR OP MAAT</div>
      </div>
      <div
        class="button"
        id="mom"
        (click)="goToUrl('https://www.groeptom.be/mom')"
      >
        <img class="button-logo" src="../assets/images/meubelopmaat-icon.svg" />
        <div class="button-naam">MEUBEL OP MAAT</div>
      </div>
      <div
        class="button"
        id="sigma"
        (click)="goToUrl('https://www.sigmashop-verfwinkel.be/')"
      >
        <img class="button-logo" src="../assets/images/sigma-logo-klein.png" />
        <div class="button-naam">SIGMA-SHOP</div>
      </div>
    </div>
  </div> -->
</div>
