<nav class="bigscreen">
  <div id="logo" routerLink="home">
    <img src="../assets/logo/Logo-T2_raam.svg" />
  </div>
  <div id="menu">
    <div class="menu-item" routerLink="home">HOME</div>
    <div class="menu-item" routerLink="zonwering">ZONWERING</div>
    <div class="menu-item" routerLink="gordijnen">GORDIJNEN</div>
    <div class="menu-item" routerLink="squid">SQUID</div>
    <div class="menu-item" routerLink="velux">VELUX</div>
    <div class="menu-item" (click)="goToSigmashop()">SIGMASHOP</div>
    <div class="menu-item" routerLink="contact">CONTACT</div>
  </div>
</nav>
<nav class="smallscreen">
  <div id="topbar">
    <div id="logo" routerLink="home">
      <div id="logo"><img src="../assets/logo/Logo-T2_raam.svg" /></div>
    </div>
    <div id="menu" (click)="menuToggle()"><fa-icon [icon]="['fas', 'bars']"></fa-icon></div>
  </div>
  <div id="menulist" [class.show]="menulist==true" [class.hide]="menulist==false">
    <div class="menu-item" routerLink="home">HOME</div>
    <div class="menu-item" routerLink="zonwering">ZONWERING</div>
    <div class="menu-item" routerLink="gordijnen">GORDIJNEN</div>
    <div class="menu-item" routerLink="squid">SQUID</div>
    <div class="menu-item" routerLink="velux">VELUX</div>
    <div class="menu-item" (click)="goToSigmashop()">SIGMASHOP</div>
    <div class="menu-item" routerLink="contact">CONTACT</div>
  </div>
</nav>
<router-outlet></router-outlet>
<footer>
  <div id="f-logo">
    <img src="../assets/logo/Logo-T2_raam.svg" />
  </div>

  <!-- <div class="">
    <div class="f-menu-item">Groep T.O.M. Olen</div>
    <div class="f-menu-item">
      <div class="hover" (click)="goToSocial('https://www.facebook.com/GROEPTOM')">
        <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
      </div>
      <div class="hover" (click)="goToSocial('https://www.instagram.com/groep_t.o.m')">
        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
      </div>
    </div>
  </div> -->
  <div class="">
    <!-- <div class="f-menu-item">Sigma Shop</div> -->
    <div class="f-menu-item">
      <!-- <div class="hover"
        (click)="
          goToSocial(
            'https://www.facebook.com/Goe-Decor-Raamdecoratie-111064657418002'
          )
        "
      >
        <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
      </div> -->
      <div class="hover"
        (click)="goToSocial('https://www.instagram.com/t2_verf_raamdecoratie/')"
      >
        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
      </div>
    </div>
  </div>
  <div id="webshop" class="f-menu-item hover">
    <div (click)="goToWebshop()" class="verfonline">
      <fa-icon [icon]="['fas', 'shopping-cart']"></fa-icon>www.verf-online.be
    </div>
  </div>
</footer>
